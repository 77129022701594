import { IAction, ActionType } from "../types";
import moment from 'moment';

export type ContractInfoModel = {
    ContractActiveDate: string
    ContractEndDate: string
    ContractStatus: string
    Message: string
    PlanName: null | string
    PortInDate: null | string
    PortInStatus: null | string
    ResponseCode: number
    RetentionEligibility: null | string
    RetentionEligibilityDate: null | string
}

const INITIAL_STATE = {
    contractList: [],
    isFetchingContracts: false,
    isSendingSMS: false,
    isSendingEmail: false,
    isResendingEmail: false,
    isResigningOnscreen: false,
    isSuccess: true,
    isRetryingContract: false,
    filters: {
        fromDate: moment().subtract(7, 'days').format('yyyy-MM-DD') as string | undefined,
        toDate: moment().format('yyyy-MM-DD') as string | undefined
    }
}

export type ContractStatusState = typeof INITIAL_STATE
export type ContractStatusFilter = typeof INITIAL_STATE.filters

const initReducer = function () {    

    let reducersByActions: any = {}

    reducersByActions[ActionType.ContractStatusUpdateFilter] = (state: ContractStatusState, actionData: ContractStatusFilter): ContractStatusState => {
        return {
            ...state,
            filters: {
                ...state.filters,
                ...actionData
            }
        }
    }

    reducersByActions[ActionType.ContractStatusGetListRequest] = (state: ContractStatusState): ContractStatusState => {
        return {
            ...state,
            isFetchingContracts: true
        }
    }

    reducersByActions[ActionType.ContractStatusGetListResponse] = (state: ContractStatusState, actionData: any): ContractStatusState => {
        return {
            ...state,
            isFetchingContracts: false,
            contractList: actionData || []
        }
    }

    reducersByActions[ActionType.ContractStatusSendSMSRequest] = (state: ContractStatusState): ContractStatusState => {
        return {
            ...state,
            isSendingSMS: true
        }
    }

    reducersByActions[ActionType.ContractStatusSendSMSResponse] = (state: ContractStatusState, actionData: any): ContractStatusState => {
        return {
            ...state,
            isSendingSMS: false,
            isSuccess: actionData.isSuccess
        }
    }

    reducersByActions[ActionType.ContractStatusSendEmailRequest] = (state: ContractStatusState): ContractStatusState => {
        return {
            ...state,
            isSendingEmail: true
        }
    }

    reducersByActions[ActionType.ContractStatusSendEmailResponse] = (state: ContractStatusState, actionData: any): ContractStatusState => {
        return {
            ...state,
            isSendingEmail: false,
            isSuccess: actionData.isSuccess
        }
    }

    reducersByActions[ActionType.ContractStatusResendEmailRequest] = (state: ContractStatusState): ContractStatusState => {
        return {
            ...state,
            isResendingEmail: true
        }
    }

    reducersByActions[ActionType.ContractStatusResendEmailResponse] = (state: ContractStatusState, actionData: any): ContractStatusState => {
        return {
            ...state,
            isResendingEmail: false,
            isSuccess: actionData.isSuccess
        }
    }

    reducersByActions[ActionType.ContractStatusResignOnscreenRequest] = (state: ContractStatusState): ContractStatusState => {
        return {
            ...state,
            isResigningOnscreen: true
        }
    }

    reducersByActions[ActionType.ContractStatusResignOnscreenResponse] = (state: ContractStatusState, actionData: any): ContractStatusState => {
        return {
            ...state,
            isResigningOnscreen: false,
            isSuccess: actionData.isSuccess
        }
    }

    reducersByActions[ActionType.ContractStatusRetryRequest] = (state: ContractStatusState): ContractStatusState => {
        return {
            ...state,
            isRetryingContract: true
        }
    }

    reducersByActions[ActionType.ContractStatusRetryResponse] = (state: ContractStatusState, actionData: any): ContractStatusState => {
        return {
            ...state,
            isRetryingContract: false,
            isSuccess: actionData.isSuccess
        }
    }

    reducersByActions[ActionType.ContractStatusCleanData] = (state: ContractStatusState): ContractStatusState => {
        return JSON.parse(JSON.stringify(INITIAL_STATE))
    }

    reducersByActions[ActionType.Logout] = (state: ContractStatusState): ContractStatusState => {
        return JSON.parse(JSON.stringify(INITIAL_STATE))
    }

    const reducer = (state = JSON.parse(JSON.stringify((INITIAL_STATE))), action: IAction): typeof INITIAL_STATE => {
        return reducersByActions[action.type] !== undefined ? reducersByActions[action.type](state, action.data) : state;
    };

    return reducer;
}

export default initReducer